import {defineStore} from 'pinia';
import {computed, watch} from 'vue';
import {useAsyncState} from '@vueuse/core';
import NotificationRepository from '../api/repositories/NotificationRepository';
import {useFeatureFlagStore} from './featureFlagStore';

export const useNotificationStore = defineStore('notification', () => {
    const featureFlagStore = useFeatureFlagStore();

    const {
        state: notifications,
        isLoading: notificationsLoading,
        execute: reload,
    } = useAsyncState(
        /**
         * @returns {Promise<Array<NotificationView>>}
         */
        async () => {
            if (!featureFlagStore.receiveNotifications.isActive) {
                return [];
            }
            return await NotificationRepository().read();
        },
        []
    );

    watch(
        () => featureFlagStore.receiveNotifications.isActive,
        isActive => {
            if (isActive) {
                void reload();
            }
        }
    );

    const unreadNotifications = computed(() =>
        notifications.value.filter(notification => !notification.readAt)
    );

    const notArchivedNotifications = computed(() =>
        notifications.value.filter(notification => !notification.archivedAt)
    );

    const archivedNotifications = computed(() =>
        notifications.value.filter(notification => notification.archivedAt)
    );

    const hasUnreadNotifications = computed(() => !!unreadNotifications.value.length);
    const hasNotArchivedNotifications = computed(() => !!notArchivedNotifications.value.length);

    /**
     *
     * @param {NotificationView} notification
     * @param {Function} callbackFn
     */
    function updateNotificationStatus(notification, callbackFn) {
        const clonedNotifications = [...notifications.value];
        const updatedNotificationIndex = clonedNotifications.findIndex(
            ({id}) => id === notification.id
        );
        callbackFn(clonedNotifications[updatedNotificationIndex]);
        notifications.value = clonedNotifications;
    }

    /**
     *
     * @param {NotificationView} notification
     */
    async function markAsArchived(notification) {
        void NotificationRepository().markAsArchived(notification.id);
        updateNotificationStatus(
            notification,
            notification => (notification.archivedAt = new Date())
        );
    }

    /**
     *
     * @param {NotificationView} notification
     */
    async function markAsRead(notification) {
        if (notification.readAt) {
            return;
        }
        await NotificationRepository().markAsRead(notification.id);
        updateNotificationStatus(notification, notification => (notification.readAt = new Date()));
    }

    return {
        notifications,
        notificationsLoading,
        unreadNotifications,
        notArchivedNotifications,
        archivedNotifications,
        hasUnreadNotifications,
        hasNotArchivedNotifications,
        markAsArchived,
        markAsRead,
    };
});
