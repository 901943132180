import debounce from 'lodash/debounce';

const debounceTime = 200;

export const trackMatomoPageView = debounce(() => {
    const _paq = window._paq;
    if (!_paq || typeof _paq.push !== 'function') {
        return;
    }
    _paq.push(['setCustomUrl', window.location.href]);
    _paq.push(['setDocumentTitle', document.title]);
    _paq.push(['trackPageView']);
}, debounceTime);
